import React, { Fragment, useState } from "react"

import HeaderAboutUs from "../../components/header_aboutus"
import KunalProfilePic from "../../static/images/AboutUs-KunalProfilePic.jpg"
import VikrantGuptaProfilePic from "../../static/images/AboutUs-VikrantGuptaProfilePic.jpg"

export default function WhoWeAre() {
  const [showVikrantSection] = useState(true)
  let heroTitle = "Who We Are"

  return (
    <HeaderAboutUs heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <div class="mb-4">
        <p>
          Apricus Wealth is a wealth management and investment advisory established with the intent
          of helping you in efficiently allocating your savings in order to secure your financial
          future. Our approach to portfolio management does not just focus on delivering healthy
          returns for our clients but also aims to do so by taking relatively low risks. Minimising
          the costs we incur on behalf of our clients in implementing our strategy also sits at the
          heart of our philosophy. Apricus Wealth also uses services of experienced independent
          consultants and research analysts to provide highest quality outcome to its investors and
          clients.
        </p>
      </div>

      {showVikrantSection && (
        <Fragment>
          <h4 class="h4 h4-sm mb-1">Vikrant Gupta, CFA</h4>
          <h5>Principal Officer and Partner</h5>
          <figure class="wraping--image wraping--image-sm">
            <img src={VikrantGuptaProfilePic} alt="Vikrant Gupta" class="img-fluid img-thumbnail" />
          </figure>
          <p>
            Vikrant Gupta has twelve years' experience gained as a portfolio manager, investment
            consultant and research analyst. He spent the last 9 years as a Portfolio Manager and
            Analyst with Antares Equities in Melbourne, focussing on companies listed on the
            Australian Stock Exchange. At Antares, he was managing assets in excess of AUD 300
            million. Prior to Antares Equities, he was an investment consulting analyst at Russell
            Investments, Melbourne, advising institutional clients on strategic asset allocation. In
            his personal capacity, he has been investing in India, Australia and globally for the
            past 12 years.
          </p>
          <br className="clearfix" />

          <p style={{ float: "left" }}>
            He graduated with a Bachelor of Business Administration from Amity University, Noida and
            a Master of Financial Analysis from La Trobe University, Melbourne. He is also a
            Chartered Financial Analyst (US) charter holder.
          </p>
        </Fragment>
      )}

      <h4 class="h4 h4-sm mb-1">Kunal Bhatia, CFA</h4>
      <h5>Partner</h5>
      <figure class="wraping--image wraping--image-sm">
        <img src={KunalProfilePic} alt="Kunal Bhatia" class="img-fluid img-thumbnail" />
        {/* <figcaption class="figcaption d-none">Kunal Bhatia, Partner</figcaption> */}
      </figure>
      <p>
        Kunal Bhatia began his career as a corporate lawyer (specialising in mergers and
        acquisitions, corporate law and securities markets) and worked with leading law firms such
        as Gide Loyrette Nouel in Paris and Touchstone Partners in Delhi for about 10 years. He is a
        self-taught investor and acts as the investment manager for his extended family. He has been
        a full time private investor since 2018 and has worked as a Person Associated with
        Investment Advice (PAIA) with iFast Financial India (a part of the Singapore Stock Exchange
        listed iFast Group) for more than 2 years.
      </p>
      <br className="clearfix" />

      <p>
        He has done his Bachelors in Commerce (Honours) from Hansraj College, University of Delhi
        and Bachelors in Laws (LL.B.) from Campus Law Centre, Faculty of Law, University of Delhi.
        He is also a Chartered Financial Analyst (US) charter holder.
      </p>
    </HeaderAboutUs>
  )
}
